import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ExiliumGuidesAtta: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page exilium-page gfl-guide'}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Attachments Farming</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_atta.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Attachments Farming</h1>
          <h2>
            When to start farming Attachments in GFL and what are the best ones
            to go for each character.
          </h2>
          <p>
            Last updated: <strong>16/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Weapons & Attachments" />
        <p>
          In this guide we will focus on the best attachments to farm, how to do
          it and more. If you're looking for generic information about how
          Weapons and Attachments work in GFL 2, check this guide.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Weapons & Attachments"
            link="/gfl-exilium/guides/weapons-and-attachments"
            image={
              <StaticImage
                src="../../../images/exilium/categories/category_attachment.webp"
                alt="Weapons & Attachments"
              />
            }
          />
        </Row>
        <SectionHeader title="When to farm Attachments?" />
        <p>
          <strong>
            We recommend farming attachment’s after you are able to clear its
            highest level stage - level 58
          </strong>{' '}
          (that unlocks when you reach account level 43). This will guarantee
          the highest drop rates for Gold attachments, usually netting you{' '}
          <strong>2-3 per run</strong>. Still, reaching that point will take
          quite some time, so don't be afraid to farm it early if you have
          excess stamina - just don't farm for perfect attachment at those
          levels.
        </p>
        <SectionHeader title="All available Attachment Sets" />
        <p>Here's a list of all available Attachments sets:</p>
        <ul>
          <li>
            <strong>Ultimate Pursuit</strong> - Increases the damage, healing,
            and shield effects of Ultimate skills by 5%. Gains 1 stack after
            each use of an Ultimate skill, up to a maximum of 4 stacks.
          </li>
          <li>
            <strong>Double Strategy</strong> - When enemy target is not near
            Cover, increases targeted damage by 10%. When enemy target is near
            Cover, increases AoE damage by 10%.
          </li>
          <li>
            <strong>Phase Strike</strong> - Deals 15% increased damage to
            targets with Phase Element debuffs.
          </li>
          <li>
            <strong>Phase Resonance</strong> - If the active skill exploits a
            phase weakness, gains Phase Boost I for 1 turn before the attack. If
            it exploits two phase weaknesses, increases damage dealt by 10%.
          </li>
          <li>
            <strong>Emergency Repair</strong> - If the allied unit's HP is fully
            healed by an Active Skill, restores 2 points of their Stability
            Index. Triggers up to once per turn.
          </li>
          <li>
            <strong>Allay Support</strong> - When using defense skills,
            additionally applies Area Defense II to allied units for 2 turns.
          </li>
          <li>
            <strong>Ballistic Boost</strong> - When dealing Physical damage, the
            damage is increased by 20%.
          </li>
          <li>
            <strong>Burn Boost</strong> - When dealing Burn damage, the damage
            is increased by 20%.
          </li>
          <li>
            <strong>Hydro Boost</strong> - When dealing Hydro damage, the damage
            is increased by 20%.
          </li>
          <li>
            <strong>Electric Boost</strong> - When dealing Electric damage, the
            damage is increased by 20%.
          </li>
          <li>
            <strong>Freeze Boost</strong> - When dealing Freeze damage, the
            damage is increased by 20%.
          </li>
          <li>
            <strong>Corrosion Boost</strong> - When dealing Corrosion damage,
            the damage is increased by 20%.
          </li>
        </ul>
        <SectionHeader title="What sets to farm?" />
        <ul>
          <li>
            The best general set that you should farm is{' '}
            <strong>Phase Strike</strong> - it's the second BIS for most
            characters and very versatile and you should get a few sets for all
            weapons - mainly AR SMG and SR.{' '}
          </li>
          <li>
            One set of <strong>Ultimate Pursuit</strong> for Suomi should be
            fine as she is the only one as of now to have it as her BIS.
          </li>
          <li>
            Another one you should get one set of is{' '}
            <strong>Ally Support</strong> for Sabrina/Groza - it has some use
            cases especially for harder content.
          </li>
          <li>
            Of course if your a whale, you can go for the more specific
            elemental set which gives a 5% additional damage, but keep in mind
            that there's plenty of elements and each character will need their
            own set.
          </li>
        </ul>
        <SectionHeader title="When to stop farming?" />
        <p>First, you should farm those sets for your Damage Dealers:</p>
        <ul>
          <li>
            4 sets of <strong>Phase Strike</strong> for AR,
          </li>
          <li>
            3 sets of <strong>Phase Strike</strong> for SMG and SR,
          </li>
          <li>
            1 set of <strong>Phase Strike</strong> for SG.
          </li>
        </ul>
        <p>
          All of those sets should come with ATK%, ATK and CRIT Rate (Crit DMG
          for Muzzles).
        </p>
        <p>The next step is farming sets for your Support characters:</p>
        <ul>
          <li>
            1 set of <strong>Allay Support</strong> with double ATK and Crit
            stats (for Sabrina),
          </li>
          <li>
            1 set of <strong>Ultimate Pursuit</strong> with double ATK stats
            (for Suomi).
          </li>
        </ul>
        <p>
          Still, you can just farm Phase Strike if you want as it's so
          universal, it just works good/well on nearly all characters. Just
          don't spend too much stamina on the Attachments farm, looking for
          perfect ones - leveling characters above level 40 takes a lot of
          resources and you will neglect your account if you just focus on
          Attachments chase.
        </p>
        <p>
          Once you hit Account level 60, raise your characters and manage to
          find and roll decent sets of Attachments, you can finally leave this
          cursed place. Instead go farm Reports/Blueprints to convert into dorm
          pulls - but this is basically the endgame that will take a very long
          time to reach in Global.
        </p>
        <SectionHeader title="Best Attachments for each character" />
        <p>
          Check the <strong>Builds and Teams</strong> tab on the character
          profile to get information about the best Attachments Sets to use. We
          will soon create a dedicated page with that info compiled!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesAtta;

export const Head: React.FC = () => (
  <Seo
    title="Attachments Farming | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="When to start farming Attachments in GFL and what are the best ones to go for each character."
    game="exilium"
  />
);
